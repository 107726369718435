import React from 'react';
import PropTypes from 'prop-types';
import FormInput from './formInput';

const NewChallengeForm = (props) => (
  <>
    <div>* indicates the field is required</div>
    <FormInput title="Scratch ID" {...props} />
    <FormInput title="Title" {...props} />
    <FormInput title="Difficulty" {...props} />
    <FormInput title="Description" {...props} />
  </>
);

NewChallengeForm.propTypes = {
  handleChangeFor: PropTypes.func.isRequired,
  allFieldValues: PropTypes.shape({}).isRequired,
  formModel: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
};

export default NewChallengeForm;
