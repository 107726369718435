import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import StripeContainer from '../components/stripe-container';
import FormContainer from '../components/form-container';
import NewChallengeForm from '../components/new-challenge-form';
import Layout from '../components/layout';
import FormInput from '../components/formInput';
import NewChallengeLinks from '../components/new-challenge-links';

const NewChallengeFromScratchForm = (props) => (
  <>
    <p>
      This is where you are able to add a new challenge,
      it will not be added as an official challenge until approved by the team.
      (And set to is_public=TRUE in the database).
    </p>
    <div>
      <Link to="/code/">See public (official) challenges at /code</Link>
    </div>
    <div>
      <Link to="/code?experimental=1">See all challenges at /code?experimental=1</Link>
    </div>
    <h2>Create from Scratch Project</h2>
    <NewChallengeForm {...props} />
  </>
);

const NewChallengeFromExistingChallengeForm = (props) => (
  <>
    <h2>Create from Code Championship Project</h2>
    <div>* indicates the field is required</div>
    <FormInput title="Existing Challenge ID" {...props} />
    <FormInput title="Title" {...props} />
    <FormInput title="Difficulty" {...props} />
    <FormInput title="Description" {...props} />
  </>
);

const NewChallengePage = () => {
  const newChallengeFields = [{
    fieldName: 'Title',
    type: 'string',
    required: true,
  }, {
    fieldName: 'Difficulty',
    type: 'string',
  }, {
    fieldName: 'Description',
    type: 'string',
    textarea: true,
  }];
  return (
    <Layout>
      <h1>New Challenge</h1>
      <div className="stripe-hr thin" />
      <StripeContainer>
        <FormContainer
          formModel={[{
            fieldName: 'Scratch ID',
            type: 'string',
            required: true,
          }, ...newChallengeFields]}
          postEndpoint="/projects/add-scratch-project"
          supportEmail="support@codechampionship.com"
          formComponent={NewChallengeFromScratchForm}
          successComponent={NewChallengeLinks}
        />
      </StripeContainer>
      <StripeContainer>
        <FormContainer
          formModel={[{
            fieldName: 'Existing Challenge ID',
            type: 'string',
            required: true,
          }, ...newChallengeFields]}
          postEndpoint="/projects/copy-challenge"
          supportEmail="support@codechampionship.com"
          formComponent={NewChallengeFromExistingChallengeForm}
          successComponent={NewChallengeLinks}
        />
      </StripeContainer>
    </Layout>
  );
};

NewChallengePage.propTypes = {
  data: PropTypes.shape({
    allEventFormFields: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }).isRequired,
  }).isRequired,
};

export default NewChallengePage;
