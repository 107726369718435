import React from 'react';
import PropTypes from 'prop-types';
import StandardOutboundLink from './standard-outbound-link';

const NewChallengeLinks = ({ resetForm, successDetails }) => {
  const { newProjectId } = successDetails;
  const editUrl = `${process.env.CODE_CHAMPIONSHIP_APP_URL}/#?challenge=${newProjectId}&player1=${newProjectId}&admin=1`;
  return (
    <center>
      <h2>It worked! The form has been submitted!</h2>
      <h3>{successDetails.message}</h3>
      <div>
        <StandardOutboundLink href={editUrl}>
          Edit Your New Challenge
        </StandardOutboundLink>
      </div>
      <div>
        <StandardOutboundLink href={editUrl} />
      </div>
      <button type="button" className="stripe-button" onMouseDown={resetForm}>Complete Another Form</button>
    </center>
  );
};

NewChallengeLinks.propTypes = {
  resetForm: PropTypes.func.isRequired,
  successDetails: PropTypes.shape({
    newProjectId: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
  }).isRequired,
};

export default NewChallengeLinks;
